import Button from '@/atoms/Button'
import LayoutBase from '@/components/shared/layout/LayoutBase'
import { ISettings } from '@/entities/types'
import useRenderWidth from '@/hooks/useRenderWidth'
import { openModal } from '@/redux/modal'
import { fromBreakpoint, toBreakpoint } from '@/styles/breakpoints'
import { GridWrapper } from '@/styles/gridElements'
import { colorPalette, textStyles } from '@/styles/styleGuide'
import { title } from 'process'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useQueryRequest } from '@/hooks/requestHooks'
import Spinner from '@/components/shared/ui/Spinner'
import useMatch from '@/hooks/useMatch'
import { Profile } from '@/types/responseTypes'
import Link from '@/atoms/Link'
import { fontWeight } from '@/styles/fonts'

const ArticleGridWrapper = styled(GridWrapper)`
  box-sizing: border-box;
  padding: 40rem 20rem 100rem;
`

const Article = styled.div`
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40rem;
  padding-left: 0;
  padding-right: 0;
`

const Title = styled.h1`
  ${textStyles.pageTitle};
  color: ${colorPalette.orange};
  ${toBreakpoint.md`
    margin-top: 40rem;
  `};

  ${fromBreakpoint.md`
    margin-top: 60rem;
  `};
`

const IntroParagraph = styled.p`
  ${textStyles.introText};
  margin-top: 0;
  margin-bottom: 20rem;
`

const EmailText = styled.span`
  ${textStyles.introText};
  font-weight: ${fontWeight.bold};
  display: inline-block;
`

const ButtonsWrapper = styled.div`
  ${fromBreakpoint.md`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20rem;`}
`

type ContentProps = {
  settings: ISettings
}

const TITLE = 'Account verwijderen'

const LAYOUT_SETTINGS = {
  hasHeader: true,
  hasFooter: true,
  hasMenu: true,
}
const META = {
  metaTitle: TITLE,
  metaKeywords: 'app',
  metaDescription: '',
}

const ForgetAccountPage = ({ settings }: ContentProps) => {
  const renderWidth = useRenderWidth()
  const dispatch = useDispatch()
  const {
    match: { params },
  } = useMatch()
  const { data, isLoading } = useQueryRequest<Profile>('getProfile', undefined, {
    projectSlug: params.projectSlug,
  })

  // Temporary Solution: Without this useEffect is it not possible to open the modal at the first click of the button.
  useEffect(() => {
    dispatch(
      openModal(colorPalette.orange, {
        type: 'anonymizeUser',
      })
    )
  }, [dispatch])

  const handleOnClick = useCallback(() => {
    dispatch(
      openModal(colorPalette.orange, {
        type: 'anonymizeUser',
      })
    )
  }, [dispatch])

  if (isLoading) {
    return (
      // @ts-ignore
      <LayoutBase
        settings={settings}
        layoutSettings={LAYOUT_SETTINGS}
        renderWidth={renderWidth}
        title={title}
        pageTitle={TITLE}
        meta={META}
      >
        <ArticleGridWrapper>
          <Article>
            <Spinner />
          </Article>
        </ArticleGridWrapper>
      </LayoutBase>
    )
  }

  return (
    // @ts-ignore
    <LayoutBase
      settings={settings}
      layoutSettings={LAYOUT_SETTINGS}
      renderWidth={renderWidth}
      title={title}
      pageTitle={TITLE}
      meta={META}
      loggedIn
    >
      <ArticleGridWrapper>
        <Article>
          <Title>Account verwijderen</Title>
          {data?.isAnonymizable ? (
            <IntroParagraph>
              Je staat op het punt om jouw account te verwijderen. Dit houdt in dat je persoonlijke
              gegevens, zoals naam en emailadres, worden verwijderd uit ons systeem en je geen
              toegang meer hebt tot Mijn Homestudios. Wil je jouw account verwijderen, klik dan op
              onderstaande knop om <EmailText>{data?.email}</EmailText> te verwijderen. Let op: na
              het verwijderen van je gegevens is het niet meer mogelijk om je account te herstellen.
            </IntroParagraph>
          ) : (
            <IntroParagraph>
              We kunnen jouw account op dit moment niet verwijderen. Dit account is namelijk het
              enige of laatste account dat aan je gebruikersprofiel is gekoppeld. Zonder gekoppeld
              account heb je geen toegang meer tot Mijn Homestudios voor jouw project. Mocht je
              hierover vragen hebben, mail dan naar:{' '}
              <Link href="mailto:info@homestudios.nl">info@homestudios.nl</Link>.
            </IntroParagraph>
          )}

          {data?.isAnonymizable && (
            <ButtonsWrapper>
              <Button
                centerText
                onClick={handleOnClick}
                text="Verwijder account"
              />
            </ButtonsWrapper>
          )}
        </Article>
      </ArticleGridWrapper>
    </LayoutBase>
  )
}

export default ForgetAccountPage
