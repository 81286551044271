import { colors } from '@/styles/colors'
import styled from 'styled-components'
// eslint-disable-next-line import/no-cycle
import FormWizardSlider from '@/components/formWizard/FormWizardSlider'

export type FormWizardSliderModalComponentProps = {
  formId: string
}

export type InputValue = string | number | boolean | Array<string | number | boolean>
export type InputName = string

const Container = styled.div`
  padding-top: 32rem;
  padding-bottom: 32rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  background: ${colors.white};
`

const Wrapper = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
`

const FormWizardSliderModal = ({ formId }: FormWizardSliderModalComponentProps) => {
  return (
    <Container>
      <Wrapper>
        <FormWizardSlider formId={formId} />
      </Wrapper>
    </Container>
  )
}

export default FormWizardSliderModal
