import Container from '@/atoms/Container'
import Text from '@/atoms/Text'
import Box from '@/atoms/Box'
import TextBox from '@/atoms/TextBox'
import Title from '@/atoms/Title'
import { WishListSubcategory } from '@/entities/wishList'
import CTA from '@/molecules/CTA'
import formatCmsImageUrl from '@/services/formatCmsImageUrl'
import OptionDefault from '@/components/houseDesign/OptionDefault'
import useOpenLegacyModal from '@/hooks/useOpenLegacyModal'
import Deadline from '@/components/molecules/Deadline'
import useResponsiveSpacing from '@/hooks/useResponsiveSpacing'
import { getPostDeadlineOptions } from '@/processes/houseDesignStatuses'
import { getDeadlineState } from '@/processes/houseDesignDeadline'
import FlyoutContainer from '@/molecules/FlyoutContainer'
import {useSelector} from "@/redux/index";

type Props = {
  subcategory: WishListSubcategory
}

const Subcategory = ({ subcategory }: Props) => {
  const userIsInCj1 = useSelector((state) => state.houseDesign.phase) === 'cj1'
  const { openDeadlinesDescriptionModal } = useOpenLegacyModal()
  const horizontalSpacing = useResponsiveSpacing()

  const {
    title,
    description,
    hasModal,
    teaserText,
    author,
    id,
    options,
    deadline,
    deadlinePassed,
  } = subcategory

  const deadlineHasPassed = deadlinePassed || getDeadlineState(deadline) === 'expired'

  const subcategoryInfo = (
    <TextBox
      marginBottom="xl"
      spacing="md"
    >
      <Box marginBottom="sm">
        <Deadline
          deadline={deadline}
          passed={deadlinePassed}
          onClick={() => {
            openDeadlinesDescriptionModal()
          }}
        />
      </Box>
      <Title
        text={title}
        level={2}
        size="md"
      />
      <Text text={description} />
      {!!teaserText && !!author?.image && (
        <CTA
          text={teaserText}
          imageUrl={formatCmsImageUrl(300, 300, author.image)}
          modalId={hasModal ? id : undefined}
        />
      )}
    </TextBox>
  )

  if (deadlineHasPassed) {
    const { selected, notSelected } = getPostDeadlineOptions(subcategory)

    return (
      <FlyoutContainer
        buttonText={userIsInCj1 ? "Niet meer beschikbaar" : "Niet gekozen"}
        flyoutContent={notSelected.map((option) => (
          <OptionDefault
            option={option}
            key={option.id}
            subcategory={subcategory}
          />
        ))}
      >
        {subcategoryInfo}
        <Box marginBottom="xl">
          {selected.map((option) => (
            <OptionDefault
              option={option}
              key={option.id}
              subcategory={subcategory}
            />
          ))}
        </Box>
      </FlyoutContainer>
    )
  }

  return (
    <Container
      paddingTop="xl"
      paddingHorizontal={horizontalSpacing}
      paddingBottom="xs"
      marginBottom="xl"
      shadow
    >
      {subcategoryInfo}
      <Box marginBottom="xl">
        {options.map((option) => (
          <OptionDefault
            option={option}
            key={option.id}
            subcategory={subcategory}
          />
        ))}
      </Box>
    </Container>
  )
}

export default Subcategory
