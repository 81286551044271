import { IPriceStructure } from '@/entities/types'
import { toBreakpoint } from '@/styles/breakpoints'
import { fontWeight } from '@/styles/fonts'
import { colorPalette, textStyles } from '@/styles/styleGuide'
import styled from 'styled-components'
import SummaryOptionsOption from './homestudio/SummaryOptionsOption'

type Props = {
  priceStructure?: IPriceStructure[]
  totalPrice: number
}

const Container = styled.div`
  border-top: 2rem solid ${colorPalette.background};
  margin: 50rem 20rem;
`

const Title = styled.h5`
  ${textStyles.subTitle};
  color: ${colorPalette.headerGrey};
  margin: 20rem 0 10rem;
`

const Items = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > div {
    width: 90%;
    color: ${colorPalette.headerGrey};
    ${textStyles.bodyText};

    ${toBreakpoint.md`
      width: 100%;
    `};

    p:first-of-type {
      position: relative;
      &::before {
        content: '●';
        color: ${colorPalette.lightOrange};
        display: inline-flex;
        width: 20rem;
      }
    }
  }

  &:last-of-type {
    > div {
      font-weight: ${fontWeight.bold};
      margin-top: 10rem;
    }

    p:first-of-type {
      text-align: end;
      padding-right: 14rem;

      &::before {
        display: none;
      }
    }

    p:last-of-type {
      position: relative;

      ${toBreakpoint.md`
        &:after {
          width: calc(100% + 28rem);
        }
      `};

      &::after {
        position: absolute;
        content: '';
        background-color: ${colorPalette.beige};
        height: 1rem;
        width: calc(100% + 35rem);
        right: 0;
        top: -9rem;

        ${toBreakpoint.md`
          width: calc(100% + 26rem);
        `};
      }
    }
  }
`

const PriceStructure = ({ priceStructure, totalPrice }: Props) => {
  if (!priceStructure?.length) {
    return null
  }

  return (
    <Container>
      <Title>Prijsopbouw</Title>
      <Items>
        {priceStructure.map(({ amount, multiple, subtotal, title }) => (
          <Item key={title}>
            <SummaryOptionsOption
              title={
                multiple && amount ? `${title} (${amount} ${amount > 1 ? 'stuks' : 'stuk'})` : title
              }
              price={subtotal}
            />
          </Item>
        ))}
        <Item>
          <SummaryOptionsOption
            title="Totaal"
            price={totalPrice}
          />
        </Item>
      </Items>
    </Container>
  )
}

export default PriceStructure
