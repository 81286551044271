import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { themes, textStyles, colorPalette } from '../styles/styleGuide';
import { fromBreakpoint } from '../styles/breakpoints';
import { Card, CardTitle, CardLink, CardTextContainer } from './shared/designElements/CardElements';
import ImageLoader from './shared/ui/ImageLoader';
import PlayIcon from './shared/icons/Play';
import DateIcon from './shared/designElements/DateIcon';
import truncateText from '../services/truncateText';
import staticData from '../constants/staticData';
import ThemeLabel from './shared/designElements/ThemeLabel';
import hasEventPassed from '../services/hasEventPassed';
import UserAvatar from './UserAvatar';
import withMatch from '@/hocs/withMatch';
var ArticleCard = function ArticleCard(_ref) {
  var renderWidth = _ref.renderWidth,
    match = _ref.match,
    slug = _ref.slug,
    eventDate = _ref.eventDate,
    type = _ref.type,
    theme = _ref.theme,
    title = _ref.title,
    isBig = _ref.isBig,
    leader = _ref.leader,
    image = _ref.image,
    label = _ref.label,
    author = _ref.author;
  var _match$params = match.params,
    projectSlug = _match$params.projectSlug,
    env = _match$params.env;
  var articleSlug = slug;
  if (type === 'event') {
    var _eventHasPassed = hasEventPassed(eventDate);
    return __jsx(CardContainer, {
      theme: theme,
      hoverColor: _eventHasPassed ? colorPalette.headerGrey : themes[theme].active,
      color: _eventHasPassed ? 'Grey' : themes[theme].active,
      "data-e2e-articlecard-title": articleSlug,
      "data-id": "components_ArticleCard_CardContainer"
    }, __jsx(CardLink, {
      href: "/".concat(String(projectSlug), "/").concat(String(env), "/article/").concat(articleSlug),
      "data-id": "components_ArticleCard_CardLink"
    }, __jsx(CalenderContainer, {
      "data-id": "components_ArticleCard_CalenderContainer"
    }, eventDate && __jsx(DateIcon, {
      textColor: "#ffffff",
      bgColor: _eventHasPassed ? colorPalette.lightBeige : themes[theme].primary,
      date: eventDate,
      size: "big",
      "data-id": "components_ArticleCard_DateIcon"
    })), __jsx(TextContainer, {
      "data-id": "components_ArticleCard_TextContainer"
    }, __jsx(CardTitle, {
      color: _eventHasPassed ? colorPalette.beige : themes[theme].primary,
      hoverColor: themes[theme].active,
      "data-id": "components_ArticleCard_CardTitle"
    }, truncateText(title, isBig ? 110 : 60)), __jsx(Text, {
      "data-id": "components_ArticleCard_Text"
    }, truncateText(leader, 170))), _eventHasPassed && __jsx(Warning, {
      "data-id": "components_ArticleCard_Warning"
    }, staticData.passedEventNotification)));
  }
  return __jsx(CardContainer, {
    theme: theme,
    hoverColor: themes[theme].active,
    "data-e2e-articlecard-title": articleSlug,
    "data-id": "components_ArticleCard_CardContainer"
  }, __jsx(CardLink, {
    href: "/".concat(String(projectSlug), "/").concat(String(env), "/article/").concat(articleSlug),
    "data-id": "components_ArticleCard_CardLink"
  }, __jsx(ImageContainer, {
    sizeBig: !!leader,
    theme: theme,
    "data-id": "components_ArticleCard_ImageContainer"
  }, image && image.src && __jsx(LoaderWrapper, {
    "data-id": "components_ArticleCard_LoaderWrapper"
  }, __jsx(ImageLoader, {
    src: image.src,
    color: themes[theme].tertiary,
    mode: "bg",
    width: renderWidth,
    "data-id": "components_ArticleCard_ImageLoader"
  })), type === 'video' && __jsx(PlayIcon, {
    color: "#FFFFFF",
    "data-id": "components_ArticleCard_PlayIcon"
  }), __jsx(ThemeLabelContainer, {
    "data-id": "components_ArticleCard_ThemeLabelContainer"
  }, __jsx(ThemeLabel, {
    label: label,
    theme: theme,
    "data-id": "components_ArticleCard_ThemeLabel"
  }))), __jsx(TextContainer, {
    hasLeader: leader,
    "data-id": "components_ArticleCard_TextContainer"
  }, author && __jsx(UserAvatar, {
    mode: "imageLoader",
    size: "larger",
    imageUrl: author.image,
    name: author.name,
    "data-id": "components_ArticleCard_UserAvatar"
  }), __jsx(CardTitle, {
    color: themes[theme].primary,
    hoverColor: themes[theme].active,
    "data-id": "components_ArticleCard_CardTitle"
  }, truncateText(title, isBig ? 110 : 60)), leader && __jsx(Text, {
    "data-id": "components_ArticleCard_Text"
  }, truncateText(leader, 170)))));
};
export default withMatch(ArticleCard);
var LoaderWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
var CardContainer = styled(Card)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  &:hover {\n    ", " {\n      transform: scale(1.03) translateZ(0);\n    }\n  }\n"])), LoaderWrapper);
var ImageContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-grow: 1;\n  max-height: 367rem;\n  position: relative;\n  overflow: hidden;\n  z-index: 1;\n  background: ", ";\n  backface-visibility: hidden;\n  transform: translateZ(0);\n\n  > svg {\n    position: absolute;\n    z-index: 3;\n    box-shadow: 0 0 200rem 0 rgba(0, 0, 0, 0.5);\n    border-radius: 50%;\n    backface-visibility: hidden;\n    transform: translateZ(0);\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n\n  ", " {\n    position: absolute;\n    z-index: 1;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100%;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n    transform: scale(1) translateZ(0);\n    transition: transform 1s ease;\n    will-change: transform;\n    backface-visibility: visible;\n  }\n"])), function (props) {
  return themes[props.theme].tertiary;
}, LoaderWrapper);
var CalenderContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding: 30rem 20rem 30rem 20rem;\n\n  > div {\n    margin-left: auto;\n  }\n"])));
var Text = styled.p(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 15rem;\n  margin-bottom: 0;\n"])), textStyles.bodyText, colorPalette.textGrey);
var TextContainer = styled(CardTextContainer)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  > div {\n    position: absolute;\n    right: 15rem;\n    top: -84rem;\n    border-width: 3rem;\n  }\n\n  ", ";\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    min-height: ", ";\n  "])), function (props) {
  return props.hasLeader ? '245rem' : '170rem';
}), fromBreakpoint.lg(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    min-height: ", ";\n  "])), function (props) {
  return props.hasLeader ? '255rem' : '170rem';
}));
var Warning = styled.span(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", " font-weight: bold;\n  color: ", ";\n  position: absolute;\n  bottom: 20rem;\n  left: 20rem;\n"])), textStyles.caption, colorPalette.textGrey);
var ThemeLabelContainer = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  display: flex;\n  position: absolute;\n  z-index: 2;\n  bottom: 0;\n\n  > * {\n    position: absolute;\n    bottom: 0;\n  }\n"])));