/**
 * Note: .storybook/mocks/useOpenLegacyModal.ts is a mock for this hook.
 */

// eslint-disable-next-line import/no-cycle
import {
  CategoryDescriptionModalProperties,
  DeadlineModalProperties,
  SubCategoryDescriptionModalProperties,
  ProductOptionRequestModalProperties,
  FullDescriptionModalProperties,
  FormWizardModalProperties,
} from '@/components/ModalComponentLoader'
import { WishListOption } from '@/entities/wishList'
import useParams from '@/hooks/useParams'
import useRenderWidth from '@/hooks/useRenderWidth'
import { openModal } from '@/redux/modal'
import { colors } from '@/styles/colors'
import { useDispatch } from 'react-redux'

type AllowedModalProperties =
  | CategoryDescriptionModalProperties
  | DeadlineModalProperties
  | FullDescriptionModalProperties
  | ProductOptionRequestModalProperties
  | SubCategoryDescriptionModalProperties
  | FormWizardModalProperties

/**
 * Helper to get simplified functions for opening various modal types in the old design/implementation.
 */
const useOpenLegacyModal = () => {
  const { projectSlug } = useParams()
  const renderWidth = useRenderWidth()
  const dispatch = useDispatch()

  const dispatchOpenModal = <ModalProperties extends AllowedModalProperties>(
    properties: ModalProperties
  ) => dispatch(openModal(colors.brand, properties))

  return {
    // CategoryDescriptionModal
    openCategoryDescriptionModal: (modalId: number) =>
      dispatchOpenModal<CategoryDescriptionModalProperties>({
        type: 'categoryDescription',
        componentProps: {
          projectSlug,
          modalId,
          renderWidth,
        },
      }),
    // DeadlineModal
    openDeadlinesDescriptionModal: () =>
      dispatchOpenModal<DeadlineModalProperties>({ type: 'deadlinesDescription' }),
    // ProductOptionDescriptionModal
    openFullDescriptionModal: (modalId: number) =>
      dispatchOpenModal<FullDescriptionModalProperties>({
        type: 'fullDescription',
        componentProps: {
          projectSlug,
          modalId,
        },
      }),
    // ProductOptionRequestModal
    openOrderRequestModal: (modalId: number, amount: number, options: WishListOption[]) =>
      dispatchOpenModal<ProductOptionRequestModalProperties>({
        type: 'orderRequest',
        componentProps: {
          projectSlug,
          modalId,
          amount,
          options,
        },
      }),
    // CategorySubDescriptionModal
    openSubcategoryDescriptionModal: (modalId: number) =>
      dispatchOpenModal<SubCategoryDescriptionModalProperties>({
        type: 'subCategoryDescription',
        componentProps: {
          projectSlug,
          modalId,
          renderWidth,
        },
      }),
    openFormWizardModal: (formId: string) => {
      dispatchOpenModal<FormWizardModalProperties>({
        type: 'formWizard',
        componentProps: {
          formId,
        },
      })
    },
  }
}

export default useOpenLegacyModal
