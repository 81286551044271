import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import Button from '@/atoms/Button'
import { updateModalOpenState, clearModalState } from '@/redux/modal'
import { useDispatch } from 'react-redux'
import { shadows, textStyles, colorPalette } from '@/styles/styleGuide'
import { fromBreakpoint, toBreakpoint } from '@/styles/breakpoints'
import { useMutationRequest } from '@/hooks/requestHooks'
import Text from '@/atoms/Text'
import { useRouter } from 'next/router'
import useParams from '@/hooks/useParams'
import Spinner from '@/components/shared/ui/Spinner'

const Container = styled.div`
  ${shadows.boxPrimary};
  align-self: center;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60rem;
`

const Title = styled.h1`
  ${textStyles.pageTitle};
  color: ${colorPalette.headerGrey};
  ${toBreakpoint.md`
    margin-top: 40rem;
  `};

  ${fromBreakpoint.md`
    margin-top: 60rem;
  `};
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 24rem;
  gap: 24rem;
`

const AnonymizeUserModal = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const {
    mutate: patchAnonymizeUser,
    isError: patchAnonymizeUserIsError,
    isLoading: patchAnonymizeUserIsLoading,
    isSuccess: patchAnonymizeUserIsSuccess,
  } = useMutationRequest('patchAnonymizeUser')
  const { projectSlug } = useParams()

  useEffect(() => {
    if (patchAnonymizeUserIsSuccess) {
      dispatch(updateModalOpenState(false))
      dispatch(clearModalState())
      router.push(`/${projectSlug}/open`)
    }
  }, [dispatch, patchAnonymizeUserIsSuccess, projectSlug, router])

  const handleSubmit = useCallback(() => {
    patchAnonymizeUser({})
  }, [patchAnonymizeUser])

  const handleCancel = useCallback(() => {
    dispatch(updateModalOpenState(false))
    dispatch(clearModalState())
  }, [dispatch])

  return (
    <Container>
      <Content>
        <Title>Weet je het zeker?</Title>
        {patchAnonymizeUserIsLoading ? (
          <Spinner />
        ) : (
          <Text>
            {patchAnonymizeUserIsError
              ? `Verwijderen van account is niet gelukt.`
              : `Wanneer je op de 'Ik weet het zeker' knop drukt, worden jouw persoonlijke gegevens verwijderd en heb je niet langer toegang tot jouw persoonlijke Homestudios omgeving.`}
          </Text>
        )}

        <ButtonContainer>
          <Button
            centerText
            onClick={handleSubmit}
            text="Ik weet het zeker"
            disabled={patchAnonymizeUserIsLoading}
          />
          <Button
            centerText
            onClick={handleCancel}
            text="Annuleren"
            variant="secondary"
            disabled={patchAnonymizeUserIsLoading}
          />
        </ButtonContainer>
      </Content>
    </Container>
  )
}

export default AnonymizeUserModal
