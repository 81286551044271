import React from 'react'
import styled from 'styled-components'
import { colorPalette } from '@/styles/styleGuide'
import formatPrice from '@/services/formatPrice'
import { fromBreakpoint } from '@/styles/breakpoints'

type SummaryOptionsProps = {
  title: string
  price: number
  amount?: number
  hidePriceDelta?: boolean
  noneFound?: boolean
}

const Option = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16rem;
  color: ${colorPalette.textGrey};
  margin-bottom: 8rem;

  ${fromBreakpoint.md`
    font-size: 18rem;
  `};
`

const OptionTitle = styled.p<{ noOption?: boolean }>`
  margin: 0;
  padding: 0;
  font-style: ${(props) => (props.noOption ? 'italic' : '')};
  width: 100%;
`

const OptionEuro = styled.p`
  padding: 0;
  margin: 0 5rem 0 auto;
  width: 50rem;
  text-align: right;
`

const OptionEuroDifference = styled.span`
  display: inline-block;
  margin-right: 5rem;

  ${fromBreakpoint.md`
    margin-right: 10rem;
  `};
`

const OptionPrice = styled.p`
  padding: 0;
  margin: 0 10rem 0 auto;
  width: 105rem;
  text-align: right;
`

const getPriceDelta = (hidePriceDelta: boolean, price: number) => {
  if (hidePriceDelta || price === 0) {
    return ''
  }

  return price > 0 ? '+' : '-'
}

const SummaryOptionsOption = ({
  noneFound,
  title,
  price,
  amount = 1,
  hidePriceDelta = false,
}: SummaryOptionsProps) => (
  <Option>
    <OptionTitle noOption={noneFound}>{title}</OptionTitle>
    {!noneFound && (
      <>
        <OptionEuro>
          <OptionEuroDifference>{getPriceDelta(hidePriceDelta, price)}</OptionEuroDifference>
          &euro;
        </OptionEuro>
        <OptionPrice>
          {formatPrice(price * amount, {
            showDecimal: true,
            showEuroSign: false,
            showNegative: false,
          })}
        </OptionPrice>
      </>
    )}
  </Option>
)

export default SummaryOptionsOption
