import { useMutationRequest } from '@/hooks/requestHooks'
import { MutationEndpoint, MutationOptions, RequestError, Variables } from '@/types/endpoints'
import { UseMutationResult } from '@tanstack/react-query'
import { ComponentType } from 'react'

export type WithMutationRequestProps<ResultType> = {
  mutation: UseMutationResult<ResultType, RequestError, Variables, unknown>
}

const withMutationRequest =
  <Props extends object, ResultType = unknown>(
    WrappedComponent: ComponentType<Props & WithMutationRequestProps<ResultType>>,
    endpointKey: MutationEndpoint,
    mutationKey?: unknown[],
    options?: MutationOptions<ResultType>
  ) =>
  (props: Props) => {
    const mutation = useMutationRequest<ResultType>(endpointKey, mutationKey, options)

    return (
      <WrappedComponent
        {...props}
        mutation={mutation}
      />
    )
  }

export default withMutationRequest
