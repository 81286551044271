import React, { FC, useState } from 'react'
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import styled from 'styled-components'
import LeftArrowIcon from '@/svg/LeftArrowIcon'
import RightArrowIcon from '@/svg/RightArrowIcon'
import UtilityButton from '@/atoms/UtilityButton'
import UnionIcon from '@/svg/UnionIcon'
import { colors, RGBAColors } from '@/styles/colors'
import { fontStyles } from '@/styles/fonts'
import { useDispatch } from 'react-redux'
import { openModal } from '@/redux/houseDesign'
import { z } from '@/styles/zIndexes'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import formatCmsImageUrl from '@/services/formatCmsImageUrl'
import CoveredImage from '@/atoms//CoveredImage'
import { ImageSliderProps } from './ImageSlider.props'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'

const Slider = styled(Swiper)`
  height: 100%;
  background: ${colors.gray20};
  z-index: ${z.slider};

  .swiper-wrapper {
    z-index: ${z.slider};
  }
`

const Slide = styled(SwiperSlide)`
  width: 100%;
  height: 100%;
`

const SlideImage = styled.img.attrs<{ src: string }>(({ src }) => ({
  src,
}))`
  object-fit: cover;
  display: block;
  height: 100%;
  width: 100%;
`

const Nav = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 8rem;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  padding: 32rem;
  z-index: ${z.sliderNav};
`

const NavButtons = styled.div`
  display: flex;
  gap: 8rem;
`

const SliderTitle = styled.span`
  ${fontStyles.title.xs}
  color: ${colors.white};
  border-radius: 8rem 0;
  background-color: ${RGBAColors.black70};
  padding: 8rem 16rem;
  display: flex;
  align-items: center;
`

const SwiperButtonPrev = () => {
  const swiper = useSwiper()
  return (
    <UtilityButton
      onClick={() => swiper.slidePrev()}
      icon={LeftArrowIcon}
    />
  )
}

const SwiperButtonNext = () => {
  const swiper = useSwiper()
  return (
    <UtilityButton
      onClick={() => swiper.slideNext()}
      icon={RightArrowIcon}
    />
  )
}

const ImageSlider: FC<ImageSliderProps> = ({
  title,
  images,
  withFullscreenButton = false,
  initialSlideIndex = 0,
  fill = true,
  squareAspect = false,
}) => {
  const {
    isLargerThan: { md },
    isHighResolution,
  } = useBreakpointInfo()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)

  if (!images || images.length === 0) {
    return null
  }

  const squareAspectSize = isHighResolution ? 2000 : 1000

  return (
    <Slider
      initialSlide={initialSlideIndex}
      loop
      allowTouchMove={false}
      spaceBetween={0}
      onSlideChange={({ activeIndex }) => {
        // Note that we subtract 1, because the activeIndex from the slider 1-based while other properties, like initialSlide, are 0-based
        setActiveIndex(activeIndex - 1)
      }}
    >
      {images.map(({ image: { name, src: unformattedSrc } }) => {
        const src = squareAspect
          ? formatCmsImageUrl(squareAspectSize, squareAspectSize, unformattedSrc)
          : unformattedSrc
        if (!src) {
          return null
        }
        return (
          <Slide key={name}>
            {fill ? (
              <SlideImage src={src} />
            ) : (
              <CoveredImage
                src={src}
                withPadding
              />
            )}
          </Slide>
        )
      })}
      <Nav>
        {title && md && <SliderTitle>{title}</SliderTitle>}
        <NavButtons>
          <SwiperButtonPrev />
          <SwiperButtonNext />
          {withFullscreenButton && (
            <UtilityButton
              onClick={() =>
                dispatch(
                  openModal({
                    type: 'ImageSlider',
                    props: { images, title, initialSlideIndex: activeIndex, fill: false },
                  })
                )
              }
              icon={UnionIcon}
            />
          )}
        </NavButtons>
      </Nav>
    </Slider>
  )
}

export default ImageSlider
